import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import esdm from "data/esdm.json";
import CallToActionesdm from "components/Call-to-actionesdm/call-to-actionesdm";

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro3It 
        title="Electronics System Design & Manufacturing"
        img="/img/slid/esdm.jpg"
        desc="Elfonze stands at the forefront of Electronics Systems Design and Manufacturing (ESDM). Unwavering in our commitment to innovation, our proficiency in embedded technology positions us as leaders in IoT, Automotive, Telecommunications and beyond."
        links={esdm.title} 
      />
      <Services2It style="4item" contentdata={esdm.enterprise} />
      
      <CallToActionesdm />
      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Electronics System Design & Manufacturing</title>
      <meta key="description" name="description" 
      content="Elfonze's proficiency in Electronics Systems Design and Manufacturing (ESDM) positions us as leaders in IoT, Automotive, Telecommunications and beyond." />
   
    </>
  )
}

export default Homepage3;
