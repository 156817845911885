import React from 'react'
import { Link } from 'gatsby'

const CallToActionesdm = ({img}) => {
    return (
      <section
        className="call-action section-padding sub-bg bg-img"
        style={{ backgroundImage: `url(${img ? img : "/img/patrn.svg"})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-10">
                  <div className="sec-head  text-left">
                    <h6 className="wow fadeIn" data-wow-delay=".5s">
                      {/* Let's Start */}
                    </h6>
                    <h3 className="wow color-font">
                      {/* LET'S DESIGN THE WORLD WE WANT */}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3 valign">
              <Link
                to={`https://esdm.elfonze.com/`}
                className="butn bord curve wow fadeInUp"
                data-wow-delay=".5s"
                target='_blank'
              >
                <span>Visit ESDM Site</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
}

export default CallToActionesdm